


import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TPersonalStat } from '@/_modules/statistics/types/personal-stat.type';
import {
  TUserStatMeetings,
  TUserStatMessages,
  TUserStatNotes,
  TUserStatPromoPages
} from '@/_modules/statistics/types/promo-page-stat.type';
import FileHelper from '@/_helpers/file.helper';
import EventHelper from '@/_helpers/event.helper';
import {TEvent} from '@/_types/event.type';
import {TContact} from '@/_types/contact.type';

@Component
export default class StatisticsPersonal extends Vue {

  @Getter('statisticStore/personal') personalStat: TPersonalStat;
  @Getter('statisticStore/isLoadingPersonal') isLoadingPersonal: boolean;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('promoPageStore/contact') myself: TContact;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get isEventOwner(): boolean {
    return EventHelper.isContactEventOrganizer(this.event, this.myself);
  }

  public get isExportEnabled(): boolean {
    return this.isEventOwner || this.isEventEditor;
  }

  public get eventEditors(): TContact[] {
    if (!this.event || !this.event.editors) {
      return [];
    }

    return this.event.editors;
  }

  public get isEventEditor(): boolean {
    if (!this.myself) {
      return false;
    }
    return !!this.eventEditors.find((editor: TContact) => editor.id === this.myself.id);
  }

  public get isCustomEvent(): boolean {
    return this.eventId === 1024;
  }

  @Watch('eventId', { immediate: true, })
  private onEventIdChange(): void {
    this.$store.dispatch('statisticStore/getPersonal', { eventId: this.eventId });
  }

  public percents(val: number, total: number): number {
    if (total !== 0 && val < total) {
      return (val / total) * 100;
    }
    return 100;
  }

  // TODO: better naming
  public order(val: number, val2: number): number {
    return (val > val2) ? 0 : 1;
  }

  public meetingsConfirmedSum(meetings: TUserStatMeetings): number {
    if (!meetings) {
      return 0;
    }
    return meetings.by_me.confirmed + meetings.to_me.confirmed;
  }

  public async exportFileContact(exportFileFormat: string): Promise<void> {
    const file = await this.$store.dispatch('statisticStore/exportContacts', {
      eventId: this.eventId,
      format: exportFileFormat
    });
    this.downloadFile(file, 'EventsWallet_contacts_statistics.' + exportFileFormat);
  }

  public async exportFilePromopage(exportFileFormat: string): Promise<void> {
    const file = await this.$store.dispatch('statisticStore/exportPromoPages', {
      eventId: this.eventId,
      format: exportFileFormat
    });
    this.downloadFile(file, 'EventsWallet_promopages_statistics.' + exportFileFormat);
  }

  public async exportFileMessages(exportFileFormat: string): Promise<void> {
    const file = await this.$store.dispatch('statisticStore/exportMessages', {
      eventId: this.eventId,
      format: exportFileFormat
    });
    this.downloadFile(file, 'EventsWallet_messages_statistics.' + exportFileFormat);
  }

  public async exportFileMeetings(exportFileFormat: string): Promise<void> {
    const file = await this.$store.dispatch('statisticStore/exportMeetings', {
      eventId: this.eventId,
      format: exportFileFormat
    });
    this.downloadFile(file, 'EventsWallet_meetings_statistics.' + exportFileFormat);
  }

  public async exportFileNotes(exportFileFormat: string): Promise<void> {
    const file = await this.$store.dispatch('statisticStore/exportNotes', {
      eventId: this.eventId,
      format: exportFileFormat
    });
    this.downloadFile(file, 'EventsWallet_notes_statistics.' + exportFileFormat);
  }

  public downloadFile(file: File, filename: string): void {
    FileHelper.downloadFile(file, filename);
  }

  public isDisabledExportContact(contacts: TUserStatPromoPages): boolean {
    if (!contacts) {
      return true;
    }
    return contacts.by_me.favorite === 0;
  }

  public isDisabledExportMeetings(meetings: TUserStatMeetings): boolean {
    if (!meetings) {
      return true;
    }
    return meetings.total_confirmed === 0;
  }

  public isDisabledExportMessages(messages: TUserStatMessages): boolean {
    if (!messages) {
      return true;
    }
    return messages.by_me.total === 0 && messages.to_me.total === 0;
  }

  public isDisabledExportNotes(notes: TUserStatNotes): boolean {
    if (!notes) {
      return true;
    }
    return notes.by_me.favorite === 0;
  }

  public isDisabledExportPromoPages(promoPages: TUserStatPromoPages): boolean {
    if (!promoPages) {
      return true;
    }
    return promoPages.by_me.favorite === 0;
  }
}
